import React from 'react';
import dynamic from 'next/dynamic';

import { css } from '@emotion/react';

const AutoLogoutBanner = dynamic(
  () => import('corgi/auto-logout-banner/components/AutoLogoutBanner'),
  { ssr: false },
);

const containerStyles = css({
  position: 'sticky',
  top: 0,
  zIndex: 9999,
  padding: 0,
  width: '100%',
  boxSizing: 'border-box',
});

export const LogoutBanner = () => (
  <div css={containerStyles}>
    <AutoLogoutBanner />
  </div>
);
