import React from 'react';
import dynamic from 'next/dynamic';

import GlobalHeader from 'corgi/global-header/components/GlobalHeader';
import type { GlobalNavigationProps } from 'frontend-utils';

import { arePropsNonEmpty } from '@utils/render';

const SideNav = dynamic(() => import('corgi/side-nav/components/SideNav'), { ssr: false });

const Header = ({ header, sideNav }: GlobalNavigationProps) => (
  <>
    <GlobalHeader {...header} />
    {arePropsNonEmpty(sideNav) && <SideNav {...sideNav} />}
  </>
);

export default Header;
